<template>
  <v-container class="white pb-16">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-breadcrumbs class="pa-0 mb-4" :items="items">
          <template #item="{ item }">
            <v-breadcrumbs-item class="global-cursor-pointer" @click="backToAdPageOrAdsPage()">
              <v-icon>mdi-chevron-left</v-icon>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <c-select
          v-if="selectAdFormatsIsShown"
          v-model="selectAdFormat"
          :items="adFormatTypesWithLabel"
          item-text="label"
          item-value="value"
          :label="$t('main.ad_format')"
          :placeholder="$t('main.ad_format')"
          class="mb-4"
          label-bold
          hide-details
          outlined
          block
          @change="changeAdRoute()"
        />

        <v-skeleton-loader :loading="adIsLoading" type="article, actions">
          <v-card-text class="pa-0">
            <ad-form :ad-format="selectAdFormat" @saved="backToAdPageOrAdsPage($event)" />
          </v-card-text>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import AdForm from '@/components/ads/AdForm/Index.vue'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'
  import routeNames from '@/types/route-names.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'CreateAndEditAds',
    components: {
      AdForm,
      CSelect
    },
    data() {
      return {
        adIsLoading: false
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdFormatTypesByAdNetwork']),
      ...mapGetters('adForm', ['getIsEditing']),
      ...mapState('adForm', ['adId', 'adFormatType', 'isDuplicate']),
      selectAdFormat: {
        get() {
          return this.adFormatType
        },
        set(adFormatType) {
          this.setAdFormatType(adFormatType)
        }
      },
      items() {
        return [{ text: this.$t('main.back') }]
      },
      adFormatTypesWithLabel() {
        return this.getAdFormatTypesByAdNetwork.map((type) => ({
          value: type,
          label: this.$t(`main.ad_format_types.${type}`)
        }))
      },
      selectAdFormatsIsShown() {
        return !this.isDuplicate && !this.getIsEditing && this.adFormatTypesWithLabel.length > 1
      }
    },
    created() {
      const { adFormat } = this.$route.query
      const adFormatIsFound = this.getAdFormatTypesByAdNetwork.includes(adFormat)
      const { id } = this.$route.params

      this.selectAdFormat = adFormatIsFound ? adFormat : this.getAdFormatTypesByAdNetwork[0]
      this.resetAdForm()

      if (this.$route.name === routeNames.DUPLICATE_AD) {
        this.setIsDuplicate(true)
      }

      if (id) {
        this.setAdId(id)
        this.fetchAd()
      }
    },
    methods: {
      ...mapActions('adForm', ['setAdId', 'resetAdForm', 'setEditAd', 'setAdFormatType', 'setIsDuplicate']),
      changeAdRoute() {
        this.$router.push({ name: routeNames.CREATE_AD, query: { adFormat: this.selectAdFormat } })
      },
      async backToAdPageOrAdsPage(ad) {
        if (this.getIsEditing) {
          await this.$router.push({ name: routeNames.AD, params: { id: this.adId } })
        } else {
          await this.$router.push({ name: routeNames.ADS, params: { createdAd: ad } })
        }
      },
      async fetchAd() {
        this.adIsLoading = true
        try {
          const response = await adsRepository.show(this.adId)
          const ad = new Ads(response)
          this.selectAdFormat = ad.adFormatType
          this.setEditAd(ad)
        } catch (error) {
          handleErrors(error)
        }
        this.adIsLoading = false
      }
    }
  }
</script>
